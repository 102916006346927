<template>
  <div>
    <heard-and-footer>
      <title-name slot="title" :my-name="title"
                  :my-background="require('../../assets/image/xinzhengjiedu.png')"></title-name>
      <div slot="content">
        <second-navi-bar v-on:getTitle="setTitle" :nav="nav" :now-page="nowPage" :parent1="parent1"></second-navi-bar>
        <router-view></router-view>
      </div>
    </heard-and-footer>
  </div>
</template>

<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
import SecondNaviBar from "@/components/public/SecondNaviBar";
export default {
  name: "policy",
  components:{
    heardAndFooter,
    TitleName,
    SecondNaviBar,
  },
  data(){
    return{
      title:'新政解读',
      nowPage:'中央政策',
      parent1:'新政解读',
      nav: {
        parent: '行业资讯',
        children: [
          {index: 1, name:'中央政策',flag:3, to: '/NewDeal'},
          {index: 2, name: '地方政策', flag:3,to: '/place'},
        ]
      }
    }
  },
  methods:{
    getTitleName(val){
      this.title=val
    },
    setTitle(val){
      this.nowPage=val
    }
  }
}
</script>

<style scoped>

</style>